"use client";
import React, { useRef, useState } from "react";
import NavItems from "@/src/lib/navitems";
import { Container, Row, Col, setConfiguration } from "react-grid-system";
import * as Styles from "./FooterStyles";
import { useWindowSize } from "@/src/custom-hooks/useWindowSize";
import Link from "next/link";
import Remark from "../../Global_Components/Remark";
import { FaMinus, FaPlus } from "react-icons/fa";

const Footer = ({ navData, lang, translations }) => {
  const size = useWindowSize();
  // STATIC QUERY DATA FOR NAV DATA
  // Data still coming in for social and legal until this is migrated
  const navItemLookup = {
    en: NavItems,
  };

  // Setup for footer
  setConfiguration({
    breakpoints: [739, 1000, 1280],
    containerWidths: [739, 1000, 1280],
    gutterWidth: 0,
    gridColumns: 12,
    defaultScreenClass: "sm",
    maxScreenClass: "lg",
  });
  const footerData = navItemLookup.en;
  return (
    <Styles.FooterBlock data-swiftype-index="false">
      <Container>
        {size.width && size.width > 1280 ? (
          <Row gutterWidth={10}>
            {/* MAIN FOOTER NAVIGATION */}
            {navData?.map((navItem, columnIndex) => (
              <Col
                className="footer-column"
                key={`footer-column-${Math.random()}`}
              >
                <div key={`footer-item-${Math.random()}/`}>
                  <div className="bold-nav-item">
                    {navItem.isLink ? (
                      <Link prefetch href={`/${lang}/${navItem.slug}/`}>
                        <Remark string={navItem.textLabel} />
                      </Link>
                    ) : (
                      <Remark string={navItem.textLabel} />
                    )}
                  </div>
                  {navItem.subNavItems.map(subNavItem => (
                    <LinkElement
                      lang={lang}
                      navItem={subNavItem.fields}
                      key={`footer-link-${Math.random()}/`}
                    />
                  ))}
                </div>
              </Col>
            ))}
            {/* SOCIAL MEDIA NAVIGATION */}
            <Col className="column-social" lg={1 / 2}>
              {Object.keys(footerData.social).map(key => (
                <a
                  href={footerData.social[key].url}
                  rel="noreferrer"
                  target="_blank"
                  key={`footer-social-${Math.random()}`}
                  title={`Brightcove ${key.toLowerCase()}`}
                >
                  {footerData.social[key].icon}
                </a>
              ))}
            </Col>
          </Row>
        ) : size.width && size.width < 1280 && size.width > 768 ? (
          <TabletFooter navData={navData} lang={lang} />
        ) : (
          <MobileFooter navData={navData} lang={lang} />
        )}
        <Row>
          {/* LEGAL NAV DATA */}
          <Col className="copy-text">
            {footerData.copy}
            {Object.keys(footerData.legal).map(key => (
              <a
                href={`/${lang}/${footerData.legal[key]}/`}
                key={`footer-link-${Math.random()}`}
              >
                {translations[key]}
              </a>
            ))}
            {/* <a key={`footer-link-${Math.random()}`}> */}
              <div id="teconsent"></div>
            {/* </a> */}
          </Col>
        </Row>
      </Container>
    </Styles.FooterBlock>
  );
};

const TabletFooter = ({ navData, lang }) => {
  const navItemLookup = {
    en: NavItems,
  };
  const footerData = navItemLookup.en;
  const getRow = (firstIndex, secondIndex) => {
    return navData.filter((item, i) => i === firstIndex || i === secondIndex);
  };
  let rowArr = [getRow(0, 1), getRow(2, 3), getRow(4, 5)];
  return (
    <>
      <div className="footer-tablet">
        {rowArr.map((arr, i) => {
          return (
            <div className="footer-tablet-column" key={i}>
              {arr.map((navItem, columnIndex) => (
                <div
                  className="footer-tablet row"
                  key={`footer-column-${Math.random()}`}
                >
                  <div key={`footer-item-${navItem.slug}/`}>
                    <div className="bold-nav-item">
                      {navItem.isLink ? (
                        <Link prefetch href={`/${lang}/${navItem.slug}/`}>
                          <Remark string={navItem.textLabel} />
                        </Link>
                      ) : (
                        <Remark string={navItem.textLabel} />
                      )}
                    </div>
                    {navItem.subNavItems.map((subNavItem, i) => (
                      <LinkElement
                        lang={lang}
                        navItem={subNavItem.fields}
                        key={`footer-link-${i}/`}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          );
        })}
      </div>
      <div className="footer-tablet-social">
        {Object.keys(footerData.social).map(key => (
          <a
            href={footerData.social[key].url}
            rel="noreferrer"
            target="_blank"
            key={`footer-social-${Math.random()}`}
            title={`Brightcove ${key.toLowerCase()}`}
          >
            {footerData.social[key].icon}
          </a>
        ))}
      </div>
    </>
  );
};

const MobileFooter = ({ navData, lang }) => {
  const navItemLookup = {
    en: NavItems,
  };
  const footerData = navItemLookup.en;
  return (
    <Styles.FooterMobile>
      <ul>
        {navData.map((navItem, columnIndex) => (
          <FooterAccordion lang={lang} item={navItem} key={columnIndex} />
        ))}
      </ul>
      <div className="footer-tablet-social">
        {Object.keys(footerData.social).map(key => (
          <a
            href={footerData.social[key].url}
            rel="noreferrer"
            target="_blank"
            key={`footer-social-${Math.random()}`}
            title={`Brightcove ${key.toLowerCase()}`}
          >
            {footerData.social[key].icon}
          </a>
        ))}
      </div>
    </Styles.FooterMobile>
  );
};

const FooterAccordion = ({ item, lang }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [accordionHeight, setAccordionHeight] = useState(0);
  const listRef = useRef<any>(null);
  return (
    <div className="footer-mobile-accordion-item">
      <button
        onClick={() => {
          if (isOpen) {
            setAccordionHeight(0);
            setIsOpen(false);
          } else {
            setAccordionHeight(listRef.current?.scrollHeight || 0);
            setIsOpen(true);
          }
        }}
        className="top-line"
      >
        <span>{item.label}</span>
        {isOpen ? (
          <FaMinus className="icon" color="#fff" />
        ) : (
          <FaPlus className="icon" color="#fff" />
        )}
      </button>
      <AccordionOpenSection accordionHeight={accordionHeight} isOpen={isOpen}>
        <ul ref={listRef}>
          {item.subNavItems.map((item, i) => (
            <SubAccordionItem lang={lang} item={item} key={i} />
          ))}
        </ul>
      </AccordionOpenSection>
    </div>
  );
};

const AccordionOpenSection = ({
  children,
  isOpen,
  accordionHeight,
}: {
  children: JSX.Element | JSX.Element[];
  isOpen: boolean;
  accordionHeight: number;
}) => {
  const accordion = useRef(null);
  return (
    <Styles.FooterMobileTopLevelAccordion
      $accordionHeight={accordionHeight}
      ref={accordion}
      aria-expanded={!isOpen}
    >
      {children}
    </Styles.FooterMobileTopLevelAccordion>
  );
};

const SubAccordionItem = ({ item, lang }) => {
  return (
    <li style={item.slug === "signin" ? { paddingBottom: "5px" } : {}}>
      <LinkElement lang={lang} navItem={item.fields} />
    </li>
  );
};

const LinkElement = ({ navItem, lang }) => {
  // VARIABLES FOR EACH TYPE OF LINK
  // Not bold, internal footer link
  const regularLink = (
    <div>
      <Link prefetch href={`/${lang}/${navItem.slug}/`}>
        <Remark string={navItem.textLabel} />
      </Link>
    </div>
  );
  // Not bold, external footer link
  const externalRegularLink = (
    <div>
      <a href={navItem.slug} rel="noreferrer" target="_blank">
        <Remark string={navItem.textLabel} />
      </a>
    </div>
  );
  // Bold, external link
  const externalBoldLink = (
    <div className="bold-nav-item bold-link-gray" style={{ fontWeight: "700" }}>
      <a href={navItem.slug} rel="noreferrer" target="_blank">
        <Remark string={navItem.textLabel} />
      </a>
    </div>
  );
  // Bold, internal link
  const internalBoldLink = (
    <div className="bold-nav-item sub-bold" style={{ fontWeight: "700" }}>
      <Link prefetch href={`/${lang}/${navItem.slug}/`}>
        <Remark string={navItem.textLabel} />
      </Link>
    </div>
  );
  // Entry that is not a link
  const unlinkedEntry = (
    <div className="bold-nav-item bold-link-gray" style={{ fontWeight: "700" }}>
      <Remark string={navItem.textLabel} />
    </div>
  );
  // If the nav item is not a link
  if (!navItem.isLink) return unlinkedEntry;
  if (navItem.slug.includes("http")) {
    // If the link is external
    if (navItem.isBold) {
      // If the link is bold and external
      return externalBoldLink;
    } else {
      // If the link is not bold and external
      return externalRegularLink;
    }
  } else {
    // If the link is internal
    if (navItem.isBold) {
      // If the link is bold and internal
      return internalBoldLink;
    } else {
      // If the link is not bold and internal
      return regularLink;
    }
  }
};

export default Footer;
